import React from 'react';
import styled from 'styled-components';
import InstructorModal from '../organisms/modals/InstructorModal';
import { Box, Flex } from './layout/Grid';
import { InstructorMetaDescription, InstructorMetaTitle } from './texts';

const Img = styled.img<{ isClickable?: boolean }>`
  width: 100%;
  border-radius: 50%;
  ${props => (props.isClickable ? 'cursor: pointer' : '')};
`;

const InstructorContent = ({ isClickable = true, onClick, ...props }) => {
  return (
    <Flex
      alignItems="center"
      onClick={isClickable ? onClick : undefined}
      mx="-10px"
    >
      <Box flex="0 0 64px" px="10px">
        <Img
          isClickable={isClickable}
          src={
            props.instructor.thumbnailUrl || '/static/profile-placeholder.svg'
          }
          alt="Profile photo"
        />
      </Box>
      <Box flex="1" px="10px">
        <Box>
          <InstructorMetaTitle>{props.instructor.name}</InstructorMetaTitle>
        </Box>
        <InstructorMetaDescription>
          {props.instructor.shortDescription}
        </InstructorMetaDescription>
      </Box>
    </Flex>
  );
};

const InstructorMeta = props => {
  if (props.isClickable === false) {
    return <InstructorContent {...props} />;
  }

  return (
    <InstructorModal slug={props.instructor.slug}>
      {handleOpenModal => (
        <InstructorContent {...props} onClick={handleOpenModal} />
      )}
    </InstructorModal>
  );
};

export default InstructorMeta;
