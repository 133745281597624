import React from 'react';
import styled from 'styled-components';

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Ratio = styled.div`
  position: relative;
  padding-top: ${props => (props.ratio ? props.ratio : '56.25%')};
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  /* background-color: ${props => (props.notFound ? 'black' : '#eaeaea')}; */
  box-shadow: ${props =>
    props.shadow && `inset 0px -112px 68px -64px rgba(0,0,0,0.75)`};
  ${props =>
    props.withRadius &&
    (props.large
      ? `
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          @media (max-width: ${props.theme.viewports.mobile}px) {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        `
      : `
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        `)};
  ${props =>
    props.isProgram
      ? `
          border-radius: 3px;
        `
      : null};
  ${props =>
    props.isLocked
      ? `
          overflow: hidden;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.8;
            background-color: #9dc9c7;
          }
          &:after {
            font-family: 'ygb-font' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            z-index: 1;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\\e94b';
            height: 24px;
            width: 24px;
            color: white;
            position: absolute;
            top: calc(50% - 12px);
            left: calc(50% - 12px);
            text-align: center;
            line-height: 24px;
          }
        `
      : null};
`;

const PLACEHOLDER_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPcWw8AAf8BPlp9PLwAAAAASUVORK5CYII=';

const ThumbnailWithRatio = ({
  className = null,
  objectFit = 'cover',
  ...props
}) => {
  return (
    <Ratio className={className} ratio={props.ratio}>
      <Image
        src={props.path || PLACEHOLDER_IMAGE}
        objectFit={objectFit}
        loading="lazy"
      />
      <BackgroundImage
        {...props}
        {...(props.isLocked ? { 'data-locked': true } : {})}
      >
        {props.children}
      </BackgroundImage>
    </Ratio>
  );
};

ThumbnailWithRatio.toString = Ratio.toString;

export default ThumbnailWithRatio;
