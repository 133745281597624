import Accordion from '@components/Accordion';
import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../components/Icon';
import { Flex } from '@components/layout/Grid';

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
`;

export const Item = styled.li`
  width: 100%;
  border-bottom: 2px solid #d8d8d8;
  padding: 24px 0;
`;

const Title = styled.p`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #1c343e;
  font-weight: 500;
  padding: 10px 0px;
  margin: 0 30px 0 0;
  cursor: pointer;
  outline: none;
`;

export const BodyText = styled.div`
  padding: 20px 0;
  margin: 0;
`;

type Props = {
  className?: string;
  list: { title: React.ReactNode; body: React.ReactNode }[];
  multipleTabsOpen?: boolean;
};

const SimpleAccordionList = (props: Props) => {
  const { className = '', list, multipleTabsOpen = true } = props;
  const [openItems, setOpenItems] = useState([]);

  return (
    <List className={className}>
      {list.map((item, i) => {
        const isOpen = openItems.includes(i);
        return (
          <Item key={i}>
            <Accordion
              head={
                <Flex alignItems="center">
                  <Title>{item.title}</Title>
                  <Icon
                    name={
                      isOpen ? 'ygb-icon-chevron-up' : 'ygb-icon-chevron-down'
                    }
                  />
                </Flex>
              }
              body={
                typeof item.body === 'string' ? (
                  <BodyText dangerouslySetInnerHTML={{ __html: item.body }} />
                ) : (
                  <BodyText>{item.body}</BodyText>
                )
              }
              onOpenChange={() => {
                if (isOpen) {
                  setOpenItems(openItems.filter(index => index !== i));
                } else if (multipleTabsOpen) {
                  setOpenItems([...openItems, i]);
                } else {
                  setOpenItems([i]);
                }
              }}
              isOpen={isOpen}
            />
          </Item>
        );
      })}
    </List>
  );
};

export default SimpleAccordionList;
