import React, { useContext } from 'react';
import Styled from 'styled-components';
import { Trans } from '@lingui/react';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import LanguageContext from '@lib/contexts/languageContext';
import SpaIcon from '@material-ui/icons/Spa';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  TextWrapper,
  Title,
  Paragraph,
  VideoWrapper,
  ActionsWrapper,
} from '@ecosystems/landing_pages/hero/components';
import Hero from '@ecosystems/landing_pages/hero/Hero';
import { Container } from '@components/layout/Grid';
import Icon, { icons } from '@components/Icon';
import Button from '@components/buttons';

const PerksWrapper = Styled(Container)`
  position: relative;
  margin: 40px 0;
  background-color: #6C97A7;
`;

const PerksHero = Styled(Hero)`
  width: 100%; 
  padding: 20px;
  
  ${TextWrapper} {
    width: 100%;
    margin: 0 auto;

    ${Title} {
      font-size: 34px;
      color: white;
      font-weight: 500;
    }

    ${Paragraph} {
      font-size: 14px;
    }

    button {
      color: #1C343E;
    }
  }

  ${VideoWrapper} {
    display: none;
  }

  ${ActionsWrapper} {
    flex-wrap: wrap;
    justify-content: center;

    button {
      margin-bottom: 10px;
    }
  }

  @media(min-width: ${({ theme }) => theme.viewports.tablet}px){
    padding: 40px 20px;

    ${TextWrapper} {
      width: 50%;
    }

    ${VideoWrapper} {
      display: initial;
      align-self: center;
      width: 360px;
      height: 360px;
      margin: 0 auto;

      [data-bg] {
        background-color: unset;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    ${ActionsWrapper} {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
`;

const List = Styled.ul`
  color: white;
  font-size: 16px;
  line-height: 1.4;
  padding-left: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    i:before {
      color: inherit;
      font-size: 14px;
    }
  }
`;

const hereBannerList = [
  {
    icon: 'ygb-icon-playlist',
    text: <Trans id="landing_page.offer.blue_section.list_item1" />,
  },
  {
    icon: <CloudDownloadIcon />,
    text: <Trans id="landing_page.offer.blue_section.list_item2" />,
  },
  {
    icon: <SpaIcon />,
    text: <Trans id="landing_page.offer.blue_section.list_item3" />,
  },
  {
    icon: <PlaylistAddIcon />,
    text: <Trans id="landing_page.offer.blue_section.list_item4" />,
  },
];

type Props = {
  className?: string;
  title?: React.ReactNode;
  bullets?: Array<{
    icon: React.ReactNode;
    text: React.ReactNode;
  }>;
  image?: string;
  actions?: React.ReactNode[];
  handleJoinClick?(): void;
};

const PerksBanner = (props: Props) => {
  const lang = useContext(LanguageContext);
  const {
    title,
    bullets = hereBannerList,
    actions,
    image,
    handleJoinClick = null,
  } = props;

  return (
    <PerksWrapper className={props.className} fullWidth>
      <Container>
        <PerksHero
          title={title ?? <Trans id="landing_page.offer.blue_section.title" />}
          image={image ?? '/static/onboarding-step2.png'}
          description={
            <List>
              {bullets.map((item, i) => (
                <li
                  key={i}
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                  <span style={{ width: 30, display: 'block' }}>
                    {typeof item.icon === 'string' ? (
                      <Icon
                        fontSize="14px"
                        name={item.icon as typeof icons[number]}
                      />
                    ) : (
                      item.icon
                    )}
                  </span>
                  <span
                    style={{ marginLeft: '10px', flex: 1, display: 'block' }}
                  >
                    {item.text}
                  </span>
                </li>
              ))}
            </List>
          }
          actions={
            actions ?? [
              <Button
                key="0"
                appearance="default-white"
                {...(typeof handleJoinClick === 'function'
                  ? { onClick: handleJoinClick }
                  : { href: `/${lang}/register/account` })}
              >
                <Trans id="landing_page.offer.blue_section.cta_text" />
              </Button>,
            ]
          }
          reverseColumns
        />
      </Container>
    </PerksWrapper>
  );
};

export default PerksBanner;
