import React from 'react';
import Icon, { icons } from './Icon';
import { Box, Flex } from './layout/Grid';
import {
  EmptyResultsText,
  EmptyResultsTextAction,
  EmptyResultsDescription,
} from './texts';

type Props = {
  className?: string;
  children?: any;
  icon?: typeof icons[number];
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  button?: React.ReactNode;
  'data-testid'?: string;
};

const EmptyResults = ({
  'data-testid': dataTestId = 'EmptyResults',
  ...props
}: Props) => (
  <Flex
    className={props.className}
    my="50px"
    flexDirection="column"
    alignItems="center"
    data-testid={dataTestId}
  >
    <Box mb="30px">
      <Icon name={props.icon} fontSize="84px" color="#7D7D7D;" />
      {props.children && props.children}
    </Box>
    <Box mb="10px">
      <EmptyResultsText>{props.title}</EmptyResultsText>
    </Box>
    {props.description ? (
      <Box mb="10px">
        <EmptyResultsDescription>{props.description}</EmptyResultsDescription>
      </Box>
    ) : null}
    {props.action ? (
      <Box>
        <EmptyResultsTextAction>{props.action}</EmptyResultsTextAction>
      </Box>
    ) : null}
    {props.button ? props.button : null}
  </Flex>
);

export default EmptyResults;
