import React from 'react';
import { Box } from './../components/layout/Grid';
import ThumbnailWithRatio from './../components/ThumbnailWithRatio';
import BaseSkeleton from './BaseSkeleton';

const InstructorModalSkeleton = () => (
  <React.Fragment>
    <ThumbnailWithRatio path="">
      <Box as={BaseSkeleton} animation={true} />
    </ThumbnailWithRatio>
    <BaseSkeleton height={10} width={100} marginBottom={20} />
    <BaseSkeleton height={10} width={100} marginBottom={20} />
    <BaseSkeleton height={10} width={60} marginBottom={20} />
  </React.Fragment>
);

export default InstructorModalSkeleton;
